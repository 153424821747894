import { CommonUtil } from '../utils/commonUtil';
import _ from 'lodash';
import dayjs from 'dayjs';

const CERTIFICATE_DATAS = [
  {
    name: 'inspectorName',
    objName: 'inspector',
    fieldName: 'enterpriseName',
    title: '점검업체이름',
    insuranceCompanyCode: [],
    pos: {
      // insuranceCode: [top, left]
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.enterpriseName');
    },
  },
  {
    name: 'shopName',
    objName: 'inspector',
    fieldName: 'shopName',
    title: '점검장이름',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: ['24.5%', '63.6%'],
    },
    getValue: (totalValues) => {
      // return _.get(totalValues, 'inspector.shopName');
      return '한독성능장';
    },
  },
  {
    name: 'VIN',
    objName: 'checkData',
    fieldName: 'VIN',
    title: '차대번호전체',
    insuranceCompanyCode: ['09', '13'], // 현대, DB
    pos: {
      '09': ['23.3%', '63.6%'],
      13: ['26%', '63.6%'],
    },
    getValue: (totalValues) => {
      if (_.get(totalValues, 'checkData.VIN').length === 17) {
        return _.get(totalValues, 'checkData.VIN');
      } else {
        return (
          _.get(totalValues, 'vehicleInformation.VIN') +
          _.get(totalValues, 'vehicleInformation.vinRemain')
        );
      }
    },
  },
  {
    name: 'statementNumber',
    objName: 'checkData',
    fieldName: 'PRFOMNC_CHCK_NO',
    title: '성능점검번호',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.PRFOMNC_CHCK_NO');
    },
  },
  {
    name: 'CNM',
    objName: 'vehicleInformation',
    fieldName: 'CNM',
    title: '차량명',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return `${_.get(totalValues, 'vehicleInformation.CNM')}${_.get(
        totalValues,
        'vehicleInformation.cnmDetail'
      )}`;
    },
  },
  {
    name: 'CHCK_DE',
    objName: 'checkData',
    fieldName: 'CHCK_DE',
    title: '성능점검일자',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return dayjs(_.get(totalValues, 'checkData.CHCK_DE')).format(
        'YYYY-MM-DD'
      );
    },
  },

  {
    name: 'VHRNO',
    objName: 'checkData',
    fieldName: 'VHRNO',
    title: '차량번호',
    insuranceCompanyCode: ['09', '13'],
    pos: {
      '09': ['23.3%', '25.8%'],
      13: ['26%', '25.8%'],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.VHRNO');
    },
  },

  {
    name: 'TRVL_DSTNC',
    objName: 'vehicleInformation',
    fieldName: 'TRVL_DSTNC',
    title: '주행거리',
    insuranceCompanyCode: ['09', '13'], // 현대, DB
    pos: {
      '09': ['21.8%', '25.8%'],
      13: ['24.5%', '25.8%'],
    },
    getValue: (totalValues) => {
      return CommonUtil.Unit.format(
        _.get(totalValues, 'vehicleInformation.TRVL_DSTNC')
      );
    },
  },

  {
    name: 'insurancePolicyNumber',
    objName: 'paymentInformation',
    fieldName: 'insurancePolicyNumber',
    title: '보험증권번호',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return _.get(totalValues, 'insurancePolicyNumber');
    },
  },

  {
    name: 'paidInsurancePriceAmount',
    objName: 'paymentInformation',
    fieldName: 'paidInsurancePriceAmount',
    title: '보험료',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return `${CommonUtil.Unit.format(
        _.get(totalValues, 'paymentInformation.insurancePrice') +
          _.get(totalValues, 'paymentInformation.insurancePriceVAT')
      )}원`;
    },
  },
  {
    name: 'insuranceType',
    title: '보험종목',
    insuranceCompanyCode: [],
    pos: {},
    getValue: (totalValues) => {
      return '자동차 성능∙상태점검 책임보험';
    },
  },
  {
    name: 'customerServiceCenterNumber',
    title: '상담번호',
    insuranceCompanyCode: ['10', '13'], // KB, DB
    pos: {
      10: [],
      13: [],
    },
    getValue: (totalValues) => {
      const numbers = {
        10: '',
        13: '1600-3528',
      };
      return _.get(
        numbers,
        _.get(totalValues, 'paymentInformation.insuranceCompany')
      );
    },
  },
];

export { CERTIFICATE_DATAS };
